<template>
  <div class="loading">
    <div>loading...</div>
  </div>
</template>

<script>
export default {
  name: "loading",
  components: {
  },
  data () {
    return {
    };
  },
  watch: {
  },
  created () {
  },
  mounted () {
  },
  methods: {
  }
};
</script>
<style scoped lang="less">
.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 30px;
  color: #21B697;
  line-height: 100vh;
}
</style>
